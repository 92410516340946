import React from "react";
import { Link } from "gatsby";
import styles from "./AddText.module.css";
import expandIcon from "../../images/down-icon.svg";

const AddText = (props) => {
  return (
    <div className={styles.addTextWrapper}>
      {props.addText.map((curr, i) => (
        <div key={i} className={styles.textUnit}>
          <p className="text">{curr}</p>
        </div>
      ))}
      <Link to={"/#" + props.to}>
        <div role="button" className={styles.closeText}>
          <img src={expandIcon} alt="Закрыть текст"></img>
        </div>
      </Link>
    </div>
  );
};

export default AddText;
