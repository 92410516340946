import React from "react";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import AddText from "../components/AddText";

const ModalExamplePage = ({ location, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {() => {
      if (!location || !location.state || !location.state.addText) {
        if (typeof window !== "undefined") {
          window.location = "/";
        }
        return () => {
          return null;
        };
      }

      return (
        <AddText
          addText={location.state.addText}
          to={location.state.to}></AddText>
      );
    }}
  </ModalRoutingContext.Consumer>
);

export default ModalExamplePage;
